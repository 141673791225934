import React from "react";
import "../../App.css";
import Cards from "../Cards";
import ExportToTop from "../ScrollToTop";

function Home() {
  return (
    <>
      {/* <HeroSection /> */}
      <Cards />
      <ExportToTop />
    </>
  );
}

export default Home;
