import React, { useEffect } from "react";

function ExportToTop() {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  // This component doesn't render anything, so it returns null
  return null;
}

export default ExportToTop;
